import React from "react";

import Icon from "../../Icon";
import * as styles from "./style.module.scss";

const Checkbox = ({
    checked,
    className = "",
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick = () => {},
    label = null,
    readOnly = false,
    ...props
}) => {
    const type = checked ? "checkboxChecked" : "checkbox";

    const checkbox = (
        <Icon
            type={type}
            className={`${className} ${styles.checkbox}`}
            onClick={() => {
                if (!readOnly) {
                    onClick();
                }
            }}
        />
    );

    if (label) {
        return (
            <div className={styles.checkboxRow} {...props}>
                {checkbox}
                <div
                    className={styles.checkboxLabel}
                    onClick={() => {
                        if (!readOnly) {
                            onClick();
                        }
                    }}
                >
                    <span>{label}</span>
                </div>
            </div>
        );
    }

    return checkbox;
};

export default Checkbox;
